import PropTypes from 'prop-types'

export const characterPropType = PropTypes.shape({
  id: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  image: PropTypes.string,
  attributes: PropTypes.shape({
    male: PropTypes.bool,
    female: PropTypes.bool,
    glasses: PropTypes.bool,
    beard: PropTypes.bool,
    moustache: PropTypes.bool,
    showingTeeth: PropTypes.bool,
    beautySpot: PropTypes.bool,
    smiling: PropTypes.bool,
    bigNose: PropTypes.bool,
    bigEars: PropTypes.bool,
    linesOnForehead: PropTypes.bool,
    bushyEyebrows: PropTypes.bool,
    joinedUpEyebrows: PropTypes.bool,
    recedingHairline: PropTypes.bool,
    blondeHair: PropTypes.bool,
    blackHair: PropTypes.bool,
    brownHair: PropTypes.bool,
    greyHair: PropTypes.bool,
    gingerHair: PropTypes.bool,
    tie: PropTypes.bool,
    blueTie: PropTypes.bool,
    redTie: PropTypes.bool,
    greyTie: PropTypes.bool,
    blackTie: PropTypes.bool,
    brownTie: PropTypes.bool,
    greenEyes: PropTypes.bool,
    brownEyes: PropTypes.bool,
    blueEyes: PropTypes.bool,
    collar: PropTypes.bool,
    whiteShirt: PropTypes.bool,
    blueShirt: PropTypes.bool,
    purpleShirt: PropTypes.bool,
    redShirt: PropTypes.bool,
    greyShirt: PropTypes.bool,
    jacket: PropTypes.bool,
    blueJacket: PropTypes.bool,
    redJacket: PropTypes.bool,
    brownJacket: PropTypes.bool,
    blackJacket: PropTypes.bool,
    greyJacket: PropTypes.bool,
  }),
})

export const playerPropType = PropTypes.shape({
  characters: PropTypes.arrayOf(PropTypes.shape({
    character: characterPropType,
    isEliminated: PropTypes.bool,
  })),
})

export const questionPropType = PropTypes.shape({
  question: PropTypes.shape({
    key: PropTypes.string,
    text: PropTypes.string,
  }),
  hasBeenAsked: PropTypes.bool,
  answer: PropTypes.bool,
})
