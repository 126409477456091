import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { connect } from 'react-redux'

import AskModal from './AskModal'
import ConfirmModal from './ConfirmModal'
import ConfirmSolveModal from './ConfirmSolveModal'
import GameOverModal from './GameOverModal'
import MainMenu from './MainMenu'
import SelectCharacterModal from './SelectCharacterModal'
import SplashScreen from './SplashScreen'
import GameScreen from './GameScreen'

import './App.css'
import {
  askQuestionAction,
  checkSolutionAction,
  initGameAction,
  resetGameAction,
  setConfirmModalStatusAction,
  setGameStatusAction,
  selectCharacterAction,
} from '../redux/actions'

import * as constants from '../redux/constants'

const App = ({
  app,
  askQuestion,
  checkSolution,
  initGame,
  opponent,
  player,
  resetGame,
  selectCharacter,
  setConfirmModalStatus,
  setGameStatus,
}) => {
  const {
    modals,
    gameStatus,
    solvingForCharacter,
  } = app
  const appClasses = classNames(
    { App: true },
    { 'App--solving': gameStatus === constants.STATUS_SOLVING },
  )
  return (
    <div className={appClasses}>
      <MainMenu />
      <div className="content">
        {gameStatus !== constants.STATUS_READY ? <GameScreen /> : <SplashScreen />}
      </div>

      <ConfirmModal
        headerText="Exit the game?"
        questionText="Are you sure you want to exit the game?"
        isOpen={modals.isExitGameOpen}
        onNoClick={() => setConfirmModalStatus(false)}
        onYesClick={() => resetGame()}
      />
      <AskModal
        answerToQuestion={app.questionAsked.answer}
        isOpen={gameStatus === constants.STATUS_ASKING}
        isPlayerActive={player.isActivePlayer}
        onAskClick={questionKey => askQuestion(questionKey)}
        onCancelClick={() => setGameStatus(constants.STATUS_ASK_OR_SOLVE)}
        questions={player.isActivePlayer ? player.questions : opponent.questions}
        questionAskedKey={!player.isActivePlayer ? app.questionAsked.question : null}
      />
      <ConfirmSolveModal
        character={solvingForCharacter.character}
        isCorrectGuess={solvingForCharacter.isCorrect}
        isIncorrectGuess={solvingForCharacter.isEliminated}
        isOpen={modals.isConfirmSolveOpen}
        isPlayerActive={player.isActivePlayer}
        onNoClick={() => setGameStatus(constants.STATUS_ASK_OR_SOLVE)}
        onYesClick={() => checkSolution(solvingForCharacter.character)}
      />
      <GameOverModal
        isOpen={gameStatus === constants.STATUS_GAME_OVER}
        isPlayerWinner={player.isWinner}
        onExitClick={() => setGameStatus(constants.STATUS_READY)}
        onNewGameClick={() => initGame()}
        opponentCharacter={opponent.selectedCharacter}
        playerCharacter={player.selectedCharacter}
      />
      <SelectCharacterModal
        characters={player.characters.map(c => c.character)}
        isOpen={gameStatus === constants.STATUS_SELECT_CHARACTER}
        selectedCharacter={player.selectedCharacter}
        onChooseClick={() => setGameStatus(constants.STATUS_ASK_OR_SOLVE)}
        onCharacterChange={character => selectCharacter(character)}
      />
    </div>
  )
}

App.propTypes = {
  app: PropTypes.object.isRequired, // eslint-disable-line
  askQuestion: PropTypes.func.isRequired,
  checkSolution: PropTypes.func.isRequired,
  initGame: PropTypes.func.isRequired,
  opponent: PropTypes.object.isRequired, // eslint-disable-line
  player: PropTypes.object.isRequired, // eslint-disable-line
  resetGame: PropTypes.func.isRequired,
  selectCharacter: PropTypes.func.isRequired,
  setConfirmModalStatus: PropTypes.func.isRequired,
  setGameStatus: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  app: state.app,
  opponent: state.game.opponent,
  player: state.game.player,
})

const mapDispatchToProps = dispatch => ({
  askQuestion: questionKey => dispatch(askQuestionAction(questionKey)),
  checkSolution: character => dispatch(checkSolutionAction(character)),
  initGame: () => dispatch(initGameAction()),
  resetGame: () => dispatch(resetGameAction),
  selectCharacter: character => dispatch(selectCharacterAction(character)),
  setConfirmModalStatus: () => dispatch(setConfirmModalStatusAction(false)),
  setGameStatus: status => dispatch(setGameStatusAction(status)),
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
