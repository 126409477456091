import React from 'react'
import PropTypes from 'prop-types'
import Label from 'semantic-ui-react/dist/commonjs/elements/Label'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import styled from 'styled-components'

import { characterPropType } from './propTypes'
import CharacterCard from './CharacterCard'

const StyledLabel = styled(Label)`
&&& {
  margin-bottom: 0;
}
`

const PlayerDetail = ({
  character,
  disabled,
  isPlayerActive,
}) => (
  <Segment
    className="playerDetail"
    disabled={disabled}
    raised={isPlayerActive}
    size="mini"
    textAlign="center"
  >
    <StyledLabel attached="top" size="mini">
      My character
    </StyledLabel>
    <CharacterCard
      character={character}
      isZoomEnabled={!disabled}
    />
  </Segment>
)

PlayerDetail.propTypes = {
  character: characterPropType.isRequired,
  disabled: PropTypes.bool.isRequired,
  isPlayerActive: PropTypes.bool,
}

PlayerDetail.defaultProps = {
  isPlayerActive: false,
}

export default PlayerDetail
