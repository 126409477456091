import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Container from 'semantic-ui-react/dist/commonjs/elements/Container'
import Divider from 'semantic-ui-react/dist/commonjs/elements/Divider'
import shuffle from 'lodash/shuffle'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { appLoadedAction } from '../../redux/actions'
import GGMLoader from '../GGMLoader'
import characters from '../../data/characters.json'
import SplashScreenHeader from './SplashScreenHeader'
import SplashScreenAnimation from './SplashScreenAnimation'
// pass this to GGMLoader so it is preloaded
import samuraiImage from '../GGMLoader/samurai-stop.png'

const LoaderContainer = styled(Container)`
&&& {
  margin-top: 4rem;
}
`

class SplashScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoaded: false,
    }
    this._characters = shuffle(characters).slice(0, 8)
  }

  async componentWillMount() {
    // preload images
    const characterImagePromises = this._characters.map(c => import(`../../data/images/${c.image}`))
    await Promise.all(characterImagePromises)
    this.setState({
      isLoaded: true,
    })
  }

  render() {
    const {
      appLoaded,
    } = this.props
    const { isLoaded } = this.state

    if (!isLoaded) {
      return (
        <LoaderContainer fluid textAlign="center">
          <GGMLoader content="Loading..." image={samuraiImage} />
        </LoaderContainer>
      )
    }

    return (
      <Container>
        <SplashScreenHeader isLoaded={isLoaded} />
        <Divider hidden fitted clearing />
        <SplashScreenAnimation
          characters={this._characters.slice(0, 4)}
          transitionOnMount
        />
        <SplashScreenAnimation
          characters={this._characters.slice(4, 8)}
          onComplete={() => appLoaded()}
          transitionOnMount
        />
      </Container>
    )
  }
}

SplashScreen.propTypes = {
  appLoaded: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  gameStatus: state.app.gameStatus,
})

const mapDispatchToProps = dispatch => ({
  appLoaded: () => dispatch(appLoadedAction),
})

export default connect(mapStateToProps, mapDispatchToProps)(SplashScreen)
