import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown'
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal'
import Card from 'semantic-ui-react/dist/commonjs/views/Card'
import Transition from 'semantic-ui-react/dist/commonjs/modules/Transition'

import QuestionFeedback from './QuestionFeedback'
import settings from '../settings.json'
import { questionPropType } from './propTypes'

class AskModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      lastKeyPress: null,
      dropdownValue: null,
    }
  }

  componentWillReceiveProps(nextProps) {
    // reset the dropdown on modal open
    const { isOpen } = this.props
    if (nextProps.isOpen !== isOpen) {
      this.setState({
        dropdownValue: null,
      })
    }
  }

  handleDropdownChange = (e, { value }) => this.setState({ dropdownValue: value })

  render() {
    const {
      answerToQuestion,
      isOpen,
      isPlayerActive,
      onAskClick,
      onCancelClick,
      questions,
      questionAskedKey,
    } = this.props

    const {
      lastKeyPress,
      dropdownValue,
    } = this.state

    const options = questions.map((q) => {
      const icon = {
        name: null,
        color: null,
      }
      if (q.hasBeenAsked && q.answer) {
        icon.name = 'checkmark'
        icon.color = 'green'
      }
      if (q.hasBeenAsked && !q.answer) {
        icon.name = 'close'
        icon.color = 'red'
      }
      return {
        key: q.question.key,
        value: q.question.key,
        text: q.question.text,
        disabled: q.hasBeenAsked,
        icon,
      }
    })

    const cpuQuestion = questions.find(q => q.question.key === questionAskedKey)
    const cpuQuestionText = cpuQuestion ? cpuQuestion.question.text : 'oops'

    return (
      <Transition
        animation="fly down"
        duration={settings.modalFlyInDuration}
        visible={isOpen}
        // this hack is the only way I can find to auto focus the dropdown
        // (the dropdown is not in the DOM before transition ends)
        onComplete={() => {
          const dropdown = document.querySelector('#QuestionDropdown input')
          if (dropdown) dropdown.focus()
        }}
      >
        <Modal
          open={isOpen}
          closeOnEscape={false}
          closeOnDimmerClick={false}
        >
          <Modal.Header>
            {isPlayerActive ? 'Your turn' : 'CPU turn'}
: Ask question
          </Modal.Header>
          <Modal.Content>
            {!isPlayerActive && <p>{cpuQuestionText}</p>}
            <Card centered>
              <Card.Content textAlign="center">
                {answerToQuestion === null && <QuestionFeedback color="yellow" iconName="question" animate={false} />}
                {answerToQuestion === false && <QuestionFeedback color="red" iconName="close" />}
                {answerToQuestion === true && <QuestionFeedback color="green" iconName="checkmark" />}
              </Card.Content>
            </Card>
            {isPlayerActive
            && (
            <Dropdown
              id="QuestionDropdown"
              fluid
              onChange={this.handleDropdownChange}
              onKeyDown={(e) => { // submit on enter
                this.setState({
                  lastKeyPress: e.keyCode,
                })
                // if return key pressed twice i.e. once to select, then again
                // submit the form (ask the question)
                // This is not perfect, as user can select with mouse and then
                // hit Return and it doesn't submit, but it's pretty good
                if (e.keyCode === 13 && lastKeyPress === 13) {
                  if (dropdownValue) onAskClick(dropdownValue)
                }
              }}
              onSearchChange={() => this.setState({ dropdownValue: null })} // reset on typing
              options={options}
              openOnFocus={false}
              placeholder="Type to search questions..."
              search
              selection
              value={dropdownValue}
            />
            )}
          </Modal.Content>
          {isPlayerActive
            && (
            <Modal.Actions>
              <Button
                disabled={answerToQuestion !== null}
                negative
                labelPosition="right"
                icon="close"
                content="Cancel"
                onClick={onCancelClick}
              />
              <Button
                disabled={answerToQuestion !== null || this.null}
                positive
                labelPosition="right"
                icon="checkmark"
                content="Ask"
                onClick={() => onAskClick(dropdownValue)}
              />
            </Modal.Actions>
            )}
        </Modal>
      </Transition>
    )
  }
}

AskModal.propTypes = {
  answerToQuestion: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  isPlayerActive: PropTypes.bool.isRequired,
  onAskClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  questions: PropTypes.arrayOf(questionPropType).isRequired,
  questionAskedKey: PropTypes.string,
}

AskModal.defaultProps = {
  answerToQuestion: null,
  questionAskedKey: null,
}

export default AskModal
