import React from 'react'
import PropTypes from 'prop-types'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid'
import { connect } from 'react-redux'
import styled from 'styled-components'

import Logo from './Logo'
import * as constants from '../../redux/constants'
import { appLoadedAction, initGameAction, setConfirmModalStatusAction } from '../../redux/actions'

const StyledRow = styled(Grid.Row)`
&&& {
  padding-bottom: 0;
  padding-top: 0;
}
`

const RightColumn = styled(Grid.Column)`
&&& {
  align-items: center;
  justify-content: flex-end;
  display: inline-flex !important;
}
`

const SplashScreenHeader = ({
  gameStatus,
  initGame,
  isLoaded,
  setConfirmModalStatus,
}) => (
  <Grid stackable>
    <StyledRow>
      <Grid.Column width={11}>
        <Logo />
      </Grid.Column>
      <RightColumn width={5}>
        <div>
          <Button
            disabled={!isLoaded || gameStatus === constants.STATUS_SOLVING}
            floated="right"
            onClick={() => (gameStatus !== constants.STATUS_READY
              ? setConfirmModalStatus(true) : initGame())}
            positive
            size="huge"
          >
            Start Game
          </Button>
        </div>
      </RightColumn>
    </StyledRow>
  </Grid>
)

SplashScreenHeader.propTypes = {
  gameStatus: PropTypes.string.isRequired,
  initGame: PropTypes.func.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  setConfirmModalStatus: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  gameStatus: state.app.gameStatus,
})

const mapDispatchToProps = dispatch => ({
  appLoaded: () => dispatch(appLoadedAction),
  initGame: () => dispatch(initGameAction()),
  setConfirmModalStatus: () => dispatch(setConfirmModalStatusAction(false)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SplashScreenHeader)
