import React from 'react'
import PropTypes from 'prop-types'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid'
import { connect } from 'react-redux'

import CharacterGrid from './CharacterGrid'
import OpponentDetail from './OpponentDetail'
import PlayerDetail from './PlayerDetail'
import { playerPropType } from './propTypes'
import * as constants from '../redux/constants'
import { setGameStatusAction, solveForCharacterAction } from '../redux/actions'

const GameScreen = ({
  gameStatus,
  opponent,
  player,
  setGameStatus,
  solveForCharacter,
}) => (
  <Grid columns={2}>
    <Grid.Column width={11}>
      <CharacterGrid
        characters={player.characters}
        cursorAsPointer={gameStatus === constants.STATUS_SOLVING && player.isActivePlayer}
        isZoomEnabled={gameStatus !== constants.STATUS_SOLVING}
        onCharacterClick={(gameStatus === constants.STATUS_SOLVING)
          ? (e, data) => solveForCharacter(data['data-character'])
          : null}
        padding={4}
        raisedCharacters={gameStatus === constants.STATUS_SOLVING}
        showSmallNames
      />

      <Button.Group>
        <Button
          color="blue"
          disabled={!player.isActivePlayer || gameStatus !== constants.STATUS_ASK_OR_SOLVE}
          onClick={() => setGameStatus(constants.STATUS_ASKING)}
        >
          <Icon name="question" />
            Ask
        </Button>
        <Button.Or />
        <Button
          color="green"
          disabled={!player.isActivePlayer || gameStatus !== constants.STATUS_ASK_OR_SOLVE}
          onClick={() => setGameStatus(constants.STATUS_SOLVING)}
        >
          <Icon name="lightbulb" />
            Solve
        </Button>
      </Button.Group>
    </Grid.Column>

    <Grid.Column width={5}>
      <PlayerDetail
        character={player.selectedCharacter}
        disabled={gameStatus === constants.STATUS_SOLVING}
        isPlayerActive={player.isActivePlayer}
      />
      <OpponentDetail
        characters={opponent.characters}
        disabled={gameStatus === constants.STATUS_SOLVING}
      />
    </Grid.Column>
  </Grid>
)

GameScreen.propTypes = {
  gameStatus: PropTypes.string.isRequired,
  opponent: playerPropType.isRequired,
  player: playerPropType.isRequired,
  setGameStatus: PropTypes.func.isRequired,
  solveForCharacter: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  gameStatus: state.app.gameStatus,
  opponent: state.game.opponent,
  player: state.game.player,
})

const mapDispatchToProps = dispatch => ({
  setGameStatus: status => dispatch(setGameStatusAction(status)),
  solveForCharacter: character => dispatch(solveForCharacterAction(character)),
})

export default connect(mapStateToProps, mapDispatchToProps)(GameScreen)
