import React from 'react'
import PropTypes from 'prop-types'
import Card from 'semantic-ui-react/dist/commonjs/views/Card'

import StyledLink from '../StyledLink'

const ExtraContent = ({ moreAboutLink, name }) => (
  <Card.Content extra textAlign="right">
    <StyledLink
      href={moreAboutLink}
      isExternal
      text={`More about ${name}`}
    />
  </Card.Content>
)

ExtraContent.propTypes = {
  moreAboutLink: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

export default ExtraContent
