import React from 'react'
import Header from 'semantic-ui-react/dist/commonjs/elements/Header'
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid'
import Card from 'semantic-ui-react/dist/commonjs/views/Card'
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup'
import sortBy from 'lodash/sortBy'

import MainMenu from './MainMenu'
import CharacterCard from './CharacterCard'
import characters from '../data/characters.json'

const BrowseScreen = () => (
  <div>
    <MainMenu />
    <Grid centered>
      <Grid.Column width={12}>
        <Header as="h1">
            All
          {' '}
          {characters.length}
          {' '}
Characters
          <Header.Subheader>
            Hover to zoom, or click on a card to view the data for that character.
          </Header.Subheader>
        </Header>
        <Card.Group itemsPerRow={4} doubling>
          {sortBy(characters, o => o.displayName).map((c) => {
            const card = (
              <CharacterCard
                key={`${c.id}-card`}
                character={c}
                cursorAsPointer
                isZoomEnabled
                showMeta
                showExtraContent
                showName={false}
              />
            )
            return (
              <Popup
                key={`${c.id}-popup`}
                flowing
                inverted
                // on={['click', 'hover']} // hover doesn't seem to work for cards
                position="bottom center"
                trigger={card}
              >
                <Popup.Content>
                  <pre>{JSON.stringify(c, null, 2)}</pre>
                </Popup.Content>
              </Popup>
            )
          })}
        </Card.Group>
      </Grid.Column>
    </Grid>
  </div>
)

export default BrowseScreen
