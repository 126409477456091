import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Card from 'semantic-ui-react/dist/commonjs/views/Card'

import CharacterImage from './CharacterImage'
import ExtraContent from './ExtraContent'
import Meta from './Meta'
import { characterPropType } from '../propTypes'

const CharacterCard = ({
  character,
  cursorAsPointer,
  isCentered,
  isCorrectGuess,
  isEliminated,
  isIncorrectGuess,
  isRaised,
  isZoomEnabled,
  onClick,
  showExtraContent,
  showMeta,
  showName,
  showSmallNames,
  willBeEliminated,
}) => {
  const cardClasses = classNames(
    { ui: true },
    { card: true },
    { character: true },
    { 'character--eliminated': isEliminated },
  )

  return (
    <Card
      as="div"
      className={cardClasses}
      raised={isRaised}
      centered={isCentered}
      data-character={character}
      onClick={onClick}
      style={{ cursor: cursorAsPointer ? 'pointer' : 'auto' }}
    >
      <CharacterImage
        imageSrc={character.image}
        isCorrectGuess={isCorrectGuess}
        isIncorrectGuess={isIncorrectGuess}
        isZoomEnabled={isZoomEnabled}
        name={character.displayName}
        willBeEliminated={willBeEliminated}
      />
      {showName && (
        <p className={`characterName${showSmallNames ? ' characterName--small' : ''}`}>
          {character.displayName}
        </p>
      )}
      {showMeta && (
        <Meta
          countries={character.meta.countries}
          name={character.displayName}
          birthYear={character.meta.birthYear}
        />
      )}
      {showExtraContent && (
        <ExtraContent
          moreAboutLink={character.meta.moreAboutLink}
          name={character.displayName}
        />
      )}
    </Card>
  )
}

CharacterCard.propTypes = {
  character: characterPropType,
  cursorAsPointer: PropTypes.bool,
  isCentered: PropTypes.bool,
  isCorrectGuess: PropTypes.bool,
  isEliminated: PropTypes.bool,
  isIncorrectGuess: PropTypes.bool,
  isRaised: PropTypes.bool,
  isZoomEnabled: PropTypes.bool,
  onClick: PropTypes.func,
  showExtraContent: PropTypes.bool,
  showMeta: PropTypes.bool,
  showName: PropTypes.bool,
  showSmallNames: PropTypes.bool,
  willBeEliminated: PropTypes.bool,
}

CharacterCard.defaultProps = {
  character: null,
  cursorAsPointer: false,
  isCentered: true,
  isCorrectGuess: false,
  isEliminated: false,
  isIncorrectGuess: false,
  isRaised: false,
  isZoomEnabled: false,
  onClick: null,
  showExtraContent: false,
  showMeta: false,
  showName: true,
  showSmallNames: false,
  willBeEliminated: false,
}

export default CharacterCard
