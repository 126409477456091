import React from 'react'
import PropTypes from 'prop-types'
import Card from 'semantic-ui-react/dist/commonjs/views/Card'
import Flag from 'semantic-ui-react/dist/commonjs/elements/Flag'
import styled from 'styled-components'

const FlagsContainer = styled.div`
float: right;
`

const Meta = ({ countries, name, birthYear }) => (
  <Card.Content>
    {countries.length
      && (
      <FlagsContainer>
        {countries.map(flag => <Flag key={flag} name={flag} />)}
      </FlagsContainer>
      )}
    <Card.Header>
      {name}
    </Card.Header>
    {birthYear
      && (
      <Card.Meta>
        b.
        {' '}
        {birthYear}
      </Card.Meta>
      )}
  </Card.Content>
)

Meta.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string.isRequired,
  birthYear: PropTypes.string,
}

Meta.defaultProps = {
  countries: [],
  birthYear: null,
}

export default Meta
