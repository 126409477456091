export const APP_LOADED = 'APP_LOADED'
export const ANSWER_QUESTION = 'ANSWER_QUESTION'
export const ASK_QUESTION = 'ASK_QUESTION'
export const CHECK_SOLUTION = 'CHECK_SOLUTION'
export const CLEAR_QUESTION_ASKED = 'CLEAR_QUESTION_ASKED'
export const CLEAR_SOLVING_FOR_CHARACTER = 'CLEAR_SOLVING_FOR_CHARACTER'
export const CONFIRM_SOLVE_CHARACTER = 'CONFIRM_SOLVE_CHARACTER'
export const CPU_TURN = 'CPU_TURN'
export const ELIMINATE_CHARACTER = 'ELIMINATE_CHARACTER'
export const ELIMINATE_CHARACTERS = 'ELIMINATE_CHARACTERS'
export const INIT_GAME = 'INIT_GAME'
export const INCORRECT_GUESS = 'INCORRECT_GUESS'
export const EXIT_GAME_MODAL_STATUS = 'EXIT_GAME_MODAL_STATUS'
export const PREPARE_FOR_ELIMINATION = 'PREPARE_FOR_ELIMINATION'
export const RESET_GAME = 'RESET_GAME'
export const SELECT_CHARACTER = 'SELECT_CHARACTER'
export const SET_GAME_STATUS = 'SET_GAME_STATUS'
export const SET_WINNER = 'SET_WINNER'
export const SOLVE_FOR_CHARACTER = 'SOLVE_FOR_CHARACTER'
export const TOGGLE_ACTIVE_PLAYER = 'TOGGLE_ACTIVE_PLAYER'

// game status constants
export const STATUS_ASK_OR_SOLVE = 'STATUS_ASK_OR_SOLVE'
export const STATUS_ASKING = 'STATUS_ASKING'
export const STATUS_CONFIRM_SOLVE = 'STATUS_CONFIRM_SOLVE'
export const STATUS_ELIMINATING_CHARACTERS = 'STATUS_ELIMINATING_CHARACTERS'
export const STATUS_GAME_OVER = 'STATUS_GAME_OVER'
export const STATUS_READY = 'STATUS_READY'
export const STATUS_SELECT_CHARACTER = 'STATUS_SELECT_CHARACTER'
export const STATUS_SOLVING = 'STATUS_SOLVING'
