import { createStore, combineReducers, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { createEpicMiddleware } from 'redux-observable'

import playerReducer from './reducers/player'
import appReducer from './reducers/app'
import rootEpic from './epics'

const epicMiddleware = createEpicMiddleware()

const store = createStore(
  combineReducers({
    app: appReducer,
    game: combineReducers({
      player: playerReducer(true),
      opponent: playerReducer(false),
    }),
  }),
  composeWithDevTools(applyMiddleware(epicMiddleware)),
)

epicMiddleware.run(rootEpic)

export default store
