// 404 page
import React from 'react'
import Container from 'semantic-ui-react/dist/commonjs/elements/Container'
import Divider from 'semantic-ui-react/dist/commonjs/elements/Divider'
import Embed from 'semantic-ui-react/dist/commonjs/modules/Embed'
import Header from 'semantic-ui-react/dist/commonjs/elements/Header'

import MainMenu from './MainMenu'

const NoMatch = () => (
  <div>
    <MainMenu />
    <Container text>
      <Header as="h1">
        404
        <Header.Subheader>
          We couldn&apos;t find that page. Perhaps Gorm can help?
          <Divider hidden />
          <Embed
            // aspectRatio="4:3"
            id="VFSHCJcw7Bs"
            placeholder="https://img.youtube.com/vi/VFSHCJcw7Bs/0.jpg"
            source="youtube"
          />
        </Header.Subheader>
      </Header>
    </Container>
  </div>
)

export default NoMatch
