import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import 'semantic-ui-css/semantic.min.css'

import Routes from './Routes'
import registerServiceWorker from './registerServiceWorker'

import store from './redux/store'

ReactDOM.render(
  <div>
    <Provider store={store}>
      <Routes />
    </Provider>
  </div>,
  document.getElementById('root'),
)
registerServiceWorker()
