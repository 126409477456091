import React from 'react'
import PropTypes from 'prop-types'
import Label from 'semantic-ui-react/dist/commonjs/elements/Label'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import styled from 'styled-components'

import CharacterGrid from './CharacterGrid'
import { characterPropType } from './propTypes'

const StyledLabel = styled(Label)`
&&& {
  margin-bottom: 0;
}
`

const OpponentDetail = ({
  characters,
  columns,
  disabled,
}) => {
  const remainingCount = characters.filter(c => !c.isEliminated).length
  return (
    <Segment
      className="opponentDetail"
      disabled={disabled}
      size="mini"
      raised={!disabled}
      textAlign="center"
    >
      <StyledLabel attached="top" size="mini">
        CPU board (
        {remainingCount}
/
        {characters.length}
)
      </StyledLabel>
      <CharacterGrid
        characters={characters}
        columns={columns}
        padding={2}
        showName={false}
      />
    </Segment>
  )
}

OpponentDetail.propTypes = {
  characters: PropTypes.arrayOf(characterPropType).isRequired,
  columns: PropTypes.number,
  disabled: PropTypes.bool.isRequired,
}

OpponentDetail.defaultProps = {
  columns: 6,
}

export default OpponentDetail
