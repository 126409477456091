import React from 'react'
import PropTypes from 'prop-types'
import Image from 'semantic-ui-react/dist/commonjs/elements/Image'
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import styled from 'styled-components'

import samuraiImage from './samurai-stop.png'

const StyledImage = styled(Image)`
&&& {
  height: 180px;
  width: 180px;
}
`

const GGMLoader = ({
  content,
  image,
  inline,
  inverted,
}) => (
  <Loader
    active
    inline={inline}
    inverted={inverted}
  >
    <Segment basic textAlign="center">
      <StyledImage
        alt={content}
        src={image || samuraiImage}
      />
      <div>{content}</div>
    </Segment>
  </Loader>
)

GGMLoader.propTypes = {
  content: PropTypes.string,
  image: PropTypes.string,
  inline: PropTypes.bool,
  inverted: PropTypes.bool,
}

GGMLoader.defaultProps = {
  content: '',
  image: null,
  inline: true,
  inverted: false,
}

export default GGMLoader
