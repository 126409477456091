import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid'
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal'
import Transition from 'semantic-ui-react/dist/commonjs/modules/Transition'
import Slider from 'react-slick'

import { characterPropType } from '../propTypes'
import CharacterCard from '../CharacterCard'
import settings from '../../settings.json'

class SelectCharacterModal extends Component {
  captureFocus = () => {
    // focus the modal so keypress events fire
    const modal = document.querySelector('#SelectCharacterModal')
    if (modal) modal.focus()
  }

  render() {
    const {
      characters,
      isOpen,
      onChooseClick,
      onCharacterChange,
      selectedCharacter,
    } = this.props

    return (
      <Transition
        animation="fly down"
        duration={settings.modalFlyInDuration}
        onComplete={() => this.captureFocus()}
        visible={isOpen}
      >
        <Modal
          open={isOpen}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          id="SelectCharacterModal"
          onKeyDown={(e) => {
            switch (e.keyCode) {
              case 13: // enter/return key
                return onChooseClick()
              case 39: // right arrow
                return this.slider.slickNext()
              case 37: // left arrow
                return this.slider.slickPrev()
              default:
                return false
            }
          }}
          // size="fullscreen"
          tabIndex={-1}
        >
          <Modal.Header>
            Select your character
          </Modal.Header>
          <Modal.Content>
            <p>Choose wisely. This is the character that your opponent will try to identify.</p>
          </Modal.Content>
          <Modal.Content className="sliderContent">
            <Grid>
              <Grid.Column
                className="slickArrowContainer slickArrowContainer--prev"
                width={2}
              >
                <Button
                  basic
                  icon="chevron left"
                  size="large"
                  onClick={() => this.slider.slickPrev()}
                />
              </Grid.Column>
              <Grid.Column width={12}>
                <Slider
                  afterChange={index => onCharacterChange(characters[index])}
                  arrows={false}
                  centerMode
                  centerPadding="0px"
                  className="selectCharacterCarousel"
                  infinite
                  // init={() => console.log('slider init')}
                  ref={(c) => { this.slider = c }}
                  responsive={[
                    {
                      breakpoint: 768,
                      settings: {
                        slidesToShow: 1,
                      },
                    },
                  ]}
                  speed={500}
                  slidesToScroll={1}
                  slidesToShow={3}
                >
                  {characters.map(c => (
                    <div key={c.id}>
                      <CharacterCard
                        character={c}
                        showName={false}
                        showMeta
                        isZoomEnabled={selectedCharacter.id === c.id}
                      />
                    </div>
                  ))}
                </Slider>
              </Grid.Column>
              <Grid.Column
                className="slickArrowContainer slickArrowContainer--next"
                width={2}
              >
                <Button
                  basic
                  icon="chevron right"
                  size="large"
                  onClick={() => this.slider.slickNext()}
                />
              </Grid.Column>
            </Grid>
          </Modal.Content>
          <Modal.Actions>
            <Button
              positive
              labelPosition="right"
              icon="checkmark"
              content={`Choose ${selectedCharacter ? selectedCharacter.displayName : ''}`}
              onClick={onChooseClick}
            />
          </Modal.Actions>
        </Modal>
      </Transition>
    )
  }
}

SelectCharacterModal.propTypes = {
  characters: PropTypes.arrayOf(characterPropType).isRequired,
  isOpen: PropTypes.bool.isRequired,
  selectedCharacter: characterPropType,
  onCharacterChange: PropTypes.func.isRequired,
  onChooseClick: PropTypes.func.isRequired,
}

SelectCharacterModal.defaultProps = {
  selectedCharacter: null,
}

export default SelectCharacterModal
