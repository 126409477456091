import { of, from, empty } from 'rxjs'
import {
  switchMap, delay, concat, concatMap, map,
} from 'rxjs/operators'
import { combineEpics, ofType } from 'redux-observable'
import shuffle from 'lodash/shuffle'
import every from 'lodash/every'
import minBy from 'lodash/minBy'

import * as actions from '../actions'
import * as constants from '../constants'
import settings from '../../settings.json'

const initGameEpic = action$ => action$.pipe(
  ofType(constants.INIT_GAME),
  switchMap(() => of(actions.setGameStatusAction(constants.STATUS_SELECT_CHARACTER))),
)

const solveForCharacterEpic = action$ => action$.pipe(
  ofType(constants.SOLVE_FOR_CHARACTER),
  switchMap(action => of(actions.setGameStatusAction(constants.CONFIRM_SOLVE_CHARACTER)).pipe(
    concat(of(actions.confirmSolveCharacterAction(action.payload))),
  )),
)

const checkSolutionEpic = (action$, store) => action$.pipe(
  ofType(constants.CHECK_SOLUTION),
  switchMap((action) => {
    // check if correct answer
    const state = store.value
    let correctCharacter
    if (state.game.player.isActivePlayer) {
      correctCharacter = state.game.opponent.selectedCharacter
    } else {
      correctCharacter = state.game.player.selectedCharacter
    }

    // correct guess. winner!
    if (correctCharacter.id === action.payload.id) {
      return from(state.game.opponent.characters
      // eliminate all other players
        .filter(c => !c.isEliminated && (c.character.id !== action.payload.id))).pipe(
        map(c => actions.eliminateCharacterAction(c.character)),
        // ...and set the active player as the game winner
        concat(of(actions.setWinnerAction)),
        concat(of(actions.setGameStatusAction(constants.STATUS_GAME_OVER)).pipe(
          delay(settings.eliminateCharacterDelay * 1.5),
        )),
      )
    }

    // else wrong guess. eliminate the character
    return of(actions.eliminateCharacterAction(action.payload)).pipe(
      concat(of(actions.incorrectGuessAction)),
      concat(of(actions.setGameStatusAction(constants.STATUS_ASK_OR_SOLVE)).pipe(
        delay(settings.eliminateCharacterDelay * 1.5),
      )),
      concat(of(actions.clearSolvingForCharacterAction)),
      concat(of(actions.toggleActivePlayerAction)),
      concat(state.game.player.isActivePlayer ? of(actions.cpuTurnAction)
        : empty()),
    )
  }),
)

const askQuestionEpic = (action$, store) => action$.pipe(
  ofType(constants.ASK_QUESTION),
  switchMap((action) => {
    const state = store.value
    const { player, opponent } = state.game

    let answerToQuestion
    if (player.isActivePlayer) {
      answerToQuestion = opponent.selectedCharacter.attributes[action.payload]
    } else {
      answerToQuestion = player.selectedCharacter.attributes[action.payload]
    }

    // if cpu turn, need to give time for modal to fly in,
    // and for the feedback animation
    const _delay = player.isActivePlayer ? 0
      : ((settings.modalFlyInDuration * 1.5) + (settings.questionAskedAnimatonDuration * 1.5))

    return of(actions.answerQuestionAction(action.payload, answerToQuestion)).pipe(
      delay(_delay),
      concat(of(actions.setGameStatusAction(constants.STATUS_ELIMINATING_CHARACTERS)).pipe(
        delay(settings.questionAskedAnimatonDuration * 1.5),
      )),
      concat(of(actions.eliminateCharactersAction)),
      concat(!player.isActivePlayer ? of(actions.toggleActivePlayerAction)
        : empty()),
    )
  }),
)

const eliminateCharactersEpic = (action$, store) => action$.pipe(
  ofType(constants.ELIMINATE_CHARACTERS),
  switchMap(() => {
    const state = store.value
    const { player, opponent } = state.game
    const { question, answer } = state.app.questionAsked

    if (player.isActivePlayer) {
      return from(player.characters
        .filter(c => !c.isEliminated)
        .filter(c => c.character.attributes[question] !== answer)).pipe(
        concatMap(c => of(actions.prepareForEliminationAction(c.character)).pipe(
          concat(of(actions.eliminateCharacterAction(c.character)).pipe(
            delay(settings.eliminateCharacterDelay),
          )),
        )),
        concat(of(actions.clearQuestionAskedAction)),
        concat(of(actions.clearSolvingForCharacterAction)),
        concat(of(actions.setGameStatusAction(constants.STATUS_ASK_OR_SOLVE))),
        concat(of(actions.toggleActivePlayerAction)),
        concat(state.game.player.isActivePlayer ? of(actions.cpuTurnAction) : empty()),
      )
    }

    return from(opponent.characters
      .filter(c => !c.isEliminated)
      .filter(c => c.character.attributes[question] !== answer)).pipe(
      map(c => actions.eliminateCharacterAction(c.character)),
      concat(of(actions.clearQuestionAskedAction)),
      concat(of(actions.clearSolvingForCharacterAction)),
      concat(of(actions.setGameStatusAction(constants.STATUS_ASK_OR_SOLVE))),
    )
  }),
)

const cpuTurnActionEpic = (action$, store) => action$.pipe(
  ofType(constants.CPU_TURN),
  switchMap(() => {
    const state = store.value
    const cpuRemainingCharacters = state.game.opponent.characters
      .filter(c => !c.isEliminated)
    const playerRemainingCharacters = state.game.player.characters
      .filter(c => !c.isEliminated)

    const solveForRandomCharacter = () => {
      const randomCharacter = cpuRemainingCharacters[Math.floor(Math.random()
          * cpuRemainingCharacters.length)].character
      return of(actions.confirmSolveCharacterAction(randomCharacter)).pipe(
        concat(of(actions.checkSolutionAction(randomCharacter)).pipe(
          delay(settings.questionAskedAnimatonDuration),
        )),
      )
    }

    // if two or fewer characters left, guess OR
    // if player has 3 or fewer characters left, guess
    if (cpuRemainingCharacters.length <= 2 || playerRemainingCharacters.length <= 3) {
      return solveForRandomCharacter()
    }

    // otherwise ask a question
    const cpuRemainingQuestions = state.game.opponent.questions
      .filter(q => !q.hasBeenAsked)

      // AI. Alpha Zero!
      // find the questions where the difference between true and
      // false answers is at the minimum
      // this question will eliminate the most characters

      // let's sort randomly so q's near the bottom of the list,
      // get asked a bit more often
    const answerCounts = shuffle(cpuRemainingQuestions.map((q) => {
      const trueCount = cpuRemainingCharacters
        .reduce((current, c) => current
          + (c.character.attributes[q.question.key] === true ? 1 : 0), 0)
      const falseCount = cpuRemainingCharacters
        .reduce((current, c) => current
          + (c.character.attributes[q.question.key] === false ? 1 : 0), 0)
      return {
        key: q.question.key,
        trueCount,
        falseCount,
        diff: Math.abs(trueCount - falseCount),
      }
    })
    // remove those questions that eliminate no characters
      .filter(q => q.trueCount > 0 && q.falseCount > 0))

    // edge case that should never occur: no questions to ask
    if (answerCounts.length === 0) return solveForRandomCharacter()

    // if diff is always 1, solve instead
    // This might not be optimal?!
    if (every(answerCounts, o => (o.trueCount === 1 || o.falseCount === 1))) {
      return solveForRandomCharacter()
    }

    const minDiffKey = minBy(answerCounts, o => o.diff).key

    return of(actions.askQuestionAction(minDiffKey)).pipe(
      concat(of(actions.setGameStatusAction(constants.STATUS_ASKING)).pipe(
        delay(settings.eliminateCharacterDelay * 1.5),
      )),
    )
  }),
)

export default combineEpics(
  askQuestionEpic,
  checkSolutionEpic,
  cpuTurnActionEpic,
  eliminateCharactersEpic,
  initGameEpic,
  solveForCharacterEpic,
)
