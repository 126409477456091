import * as constants from '../../constants'
import questions from '../../../data/questions.json'

const initialState = isHumanPlayer => ({
  characters: [], // all characters, that are eliminated as questions are asked
  isActivePlayer: isHumanPlayer, // human player goes first
  isHumanPlayer,
  isWinner: false,
  questions: [],
  selectedCharacter: null, // that the opponent has to guess
})

// the actions differ if is human player or CPU
const playerReducer = isHumanPlayer => (state = initialState(isHumanPlayer), action) => {
  switch (action.type) {
    case constants.ANSWER_QUESTION: {
      if (!state.isActivePlayer) return state
      return {
        ...state,
        questions: state.questions.map((q) => {
          if (q.question.key === action.payload.question) {
            return {
              ...q,
              hasBeenAsked: true,
              answer: action.payload.answer,
            }
          }
          return q
        }),
      }
    }
    case constants.ELIMINATE_CHARACTER: {
      if (!state.isActivePlayer) return state
      return {
        ...state,
        characters: state.characters.map((c) => {
          if (action.payload.id === c.character.id) {
            return {
              ...c,
              isEliminated: true,
              willBeEliminated: false,
            }
          }
          return c
        }),
      }
    }
    case constants.INIT_GAME: {
      const domain = action.payload // all characters in play
      return {
        ...state,
        isActivePlayer: state.isHumanPlayer, // human player goes first
        isWinner: false,
        characters: domain.map(p => ({
          character: p,
          isEliminated: false,
          willBeEliminated: false,
        })),
        questions: questions.map(q => ({
          question: q,
          hasBeenAsked: false,
          answer: null,
        })),
        selectedCharacter: state.isHumanPlayer
          ? domain[0] // just take first, makes select character carousel easy to implement
          : domain[Math.floor(Math.random() * domain.length)], // random character for CPU
      }
    }
    case constants.PREPARE_FOR_ELIMINATION:
      if (!state.isActivePlayer) return state
      return {
        ...state,
        characters: state.characters.map((c) => {
          if (action.payload.id === c.character.id) {
            return {
              ...c,
              willBeEliminated: true,
            }
          }
          return c
        }),
      }
    case constants.RESET_GAME:
      return initialState(state.isHumanPlayer)
    case constants.SELECT_CHARACTER:
      if (!isHumanPlayer) return state
      return {
        ...state,
        selectedCharacter: action.payload,
      }
    case constants.SET_WINNER: {
      if (!state.isActivePlayer) return state
      return {
        ...state,
        isWinner: true,
      }
    }
    case constants.TOGGLE_ACTIVE_PLAYER:
      return {
        ...state,
        isActivePlayer: !state.isActivePlayer,
      }
    default:
      return state
  }
}

export default playerReducer
