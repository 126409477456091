import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import App from './components/App'
import NoMatch from './components/NoMatch'
import BrowseScreen from './components/BrowseScreen'
import HelpScreen from './components/HelpScreen'
import AboutScreen from './components/AboutScreen'

const Routes = () => (
  <Router>
    <Switch>
      <Route exact path="/" render={() => <App />} />
      <Route exact path="/browse" component={BrowseScreen} />
      <Route exact path="/help" component={HelpScreen} />
      <Route exact path="/about" component={AboutScreen} />
      <Route component={NoMatch} />
    </Switch>
  </Router>
)

export default Routes
