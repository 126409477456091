import * as constants from '../../constants'

const initialState = {
  modals: {
    isConfirmSolveOpen: false,
    isExitGameOpen: false,
  },
  isLoaded: false,
  gameStatus: constants.STATUS_READY,
  solvingForCharacter: {
    character: null,
    isCorrect: false,
    isEliminated: false,
  },
  questionAsked: {
    answer: null,
    question: null,
  },
}

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.APP_LOADED:
      return {
        ...state,
        isLoaded: true,
      }
    case constants.ANSWER_QUESTION:
      return {
        ...state,
        questionAsked: {
          ...state.questionAsked,
          answer: action.payload.answer,
        },
      }
    case constants.ASK_QUESTION:
      return {
        ...state,
        questionAsked: {
          ...state.questionAsked,
          question: action.payload,
        },
      }
    case constants.CLEAR_SOLVING_FOR_CHARACTER:
      return {
        ...state,
        solvingForCharacter: {
          character: null,
          isCorrect: false,
          isEliminated: false,
        },
      }
    case constants.CLEAR_QUESTION_ASKED:
      return {
        ...state,
        questionAsked: {
          answer: null,
          question: null,
        },
      }
    case constants.CONFIRM_SOLVE_CHARACTER: {
      return {
        ...state,
        modals: {
          ...state.modals,
          isConfirmSolveOpen: true,
        },
        solvingForCharacter: {
          ...state.solvingForCharacter,
          character: action.payload,
        },
      }
    }
    case constants.INCORRECT_GUESS:
      return {
        ...state,
        solvingForCharacter: {
          ...state.solvingForCharacter,
          isEliminated: true,
        },
      }
    case constants.EXIT_GAME_MODAL_STATUS:
      return {
        ...state,
        modals: {
          ...state.modals,
          isExitGameOpen: action.payload,
        },
      }
    case constants.RESET_GAME:
      return initialState
    case constants.SET_GAME_STATUS:
      return {
        ...state,
        modals: {
          ...state.modals,
          isConfirmSolveOpen: false,
        },
        gameStatus: action.payload,
      }
    case constants.SET_WINNER:
      return {
        ...state,
        solvingForCharacter: {
          ...state.solvingForCharacter,
          isCorrect: true,
        },
      }
    case constants.SOLVE_FOR_CHARACTER:
      return {
        ...state,
        solvingForCharacter: {
          ...state.solvingForCharacter,
          isCorrect: false,
          isEliminated: false,
        },
      }
    default:
      return state
  }
}

export default appReducer
