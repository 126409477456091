import React from 'react'
import PropTypes from 'prop-types'
import Card from 'semantic-ui-react/dist/commonjs/views/Card'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import Transition from 'semantic-ui-react/dist/commonjs/modules/Transition'

import CharacterCard from '../CharacterCard'
import { characterPropType } from '../propTypes'
import settings from '../../settings.json'

const animations = [
  'scale',
  'horizontal flip', 'vertical flip',
  'drop',
  'fly left', 'fly right', 'fly up', 'fly down',
  'slide down', 'slide up', 'slide right',
]

const SplashScreenAnimation = ({
  characters,
  onComplete,
}) => (
  <Transition
    animation={animations[Math.floor(Math.random() * animations.length)]}
    duration={settings.splashScreenCardsAnimationDuration}
    onComplete={onComplete}
    transitionOnMount
  >
    {/* Card.Group is wrapped in a Segment so the Transition doesn't
          affect the display of the cards. */}
    <Segment vertical basic>
      <Card.Group centered itemsPerRow={4}>
        {characters.map(c => (
          <CharacterCard
            key={c.id}
            character={c}
            showName={false}
          />
        ))}
      </Card.Group>
    </Segment>
  </Transition>
)

SplashScreenAnimation.propTypes = {
  characters: PropTypes.arrayOf(characterPropType).isRequired,
  onComplete: PropTypes.func,
}

SplashScreenAnimation.defaultProps = {
  onComplete: null,
}

export default SplashScreenAnimation
