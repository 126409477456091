import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'
import Transition from 'semantic-ui-react/dist/commonjs/modules/Transition'

import settings from '../settings.json'

const QuestionFeedback = ({ animate, color, iconName }) => {
  const icon = (
    <Icon
      className="questionFeedback"
      color={color}
      name={iconName}
      size="massive"
    />
  )
  if (!animate) return icon

  return (
    <Transition
      animation="flash"
      duration={settings.eliminateCharacterDelay}
      transitionOnMount
    >
      {icon}
    </Transition>
  )
}

QuestionFeedback.propTypes = {
  animate: PropTypes.bool,
  color: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
}

QuestionFeedback.defaultProps = {
  animate: true,
}

export default QuestionFeedback
