import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Image from 'semantic-ui-react/dist/commonjs/elements/Image'
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader'
import ReactImageMagnify from 'react-image-magnify'

import QuestionFeedback from '../QuestionFeedback'

class CharacterImage extends Component {
  state = {
    image: null,
  }

  // Code Splitting with Dynamic import()
  // https://reactjs.org/blog/2017/05/18/whats-new-in-create-react-app.html
  componentDidMount() {
    this._isMounted = true
    const { imageSrc } = this.props
    this.loadImage(imageSrc)
  }

  // Make sure the image updates
  componentWillReceiveProps(nextProps) {
    if (!this._isMounted) return
    const { imageSrc } = this.props
    if (imageSrc !== nextProps.imageSrc) {
      this.loadImage(nextProps.imageSrc)
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  loadImage = async (image) => {
    const loadedImage = await import(`../../data/images/${image}`)
    if (!this._isMounted) return
    this.setState({
      image: loadedImage.default,
    })
  }

  render() {
    const {
      isCorrectGuess,
      isIncorrectGuess,
      isZoomEnabled,
      name,
      willBeEliminated,
    } = this.props

    const { image } = this.state

    if (!image) return <Loader active />

    return (
      <div className="characterContainer">
        {isZoomEnabled ? (
          <ReactImageMagnify
            {...{
              smallImage: {
                alt: '',
                src: image,
                isFluidWidth: true,
              },
              largeImage: {
                alt: name,
                src: image,
                width: 600,
                height: 390,
              },
              imageClassName: 'image ui',
              enlargedImagePosition: 'over',
            }}
          />
        )
          : <Image src={image} />}
        {isCorrectGuess && <QuestionFeedback color="green" iconName="checkmark" />}
        {(isIncorrectGuess || willBeEliminated) && <QuestionFeedback color="red" iconName="close" />}
      </div>
    )
  }
}

CharacterImage.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  isCorrectGuess: PropTypes.bool,
  isIncorrectGuess: PropTypes.bool,
  isZoomEnabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  willBeEliminated: PropTypes.bool,
}

CharacterImage.defaultProps = {
  isIncorrectGuess: false,
  isCorrectGuess: false,
  isZoomEnabled: false,
  willBeEliminated: false,
}

export default CharacterImage
