import React from 'react'
import PropTypes from 'prop-types'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal'
import Transition from 'semantic-ui-react/dist/commonjs/modules/Transition'
import { characterPropType } from './propTypes'
import CharacterCard from './CharacterCard'

import settings from '../settings.json'

// TODO: Transition not working

const ConfirmSolveModal = ({
  character,
  isCorrectGuess,
  isIncorrectGuess,
  isOpen,
  isPlayerActive,
  onYesClick,
  onNoClick,
}) => {
  if (!character) return null
  return (
    <Transition
      animation="fly down"
      duration={settings.modalFlyInDuration}
      visible={isOpen}
    >
      <Modal
        open={isOpen}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          {isPlayerActive ? 'Your turn' : 'CPU turn'}
: Solve
        </Modal.Header>
        <Modal.Content>
          <p>
Are you sure you want to guess
            {' '}
            {character.displayName}
?
          </p>
          <CharacterCard
            character={character}
            isCorrectGuess={isCorrectGuess}
            isIncorrectGuess={isIncorrectGuess}
            showName={false}
          />
        </Modal.Content>
        {isPlayerActive
        && (
        <Modal.Actions>
          <Button
            disabled={isIncorrectGuess || isCorrectGuess}
            negative
            labelPosition="right"
            icon="close"
            content="No"
            onClick={onNoClick}
          />
          <Button
            disabled={isIncorrectGuess || isCorrectGuess}
            positive
            labelPosition="right"
            icon="checkmark"
            content="Yes"
            onClick={onYesClick}
          />
        </Modal.Actions>
        )}
      </Modal>
    </Transition>
  )
}

ConfirmSolveModal.propTypes = {
  character: characterPropType,
  isCorrectGuess: PropTypes.bool.isRequired,
  isIncorrectGuess: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isPlayerActive: PropTypes.bool.isRequired,
  onNoClick: PropTypes.func.isRequired,
  onYesClick: PropTypes.func.isRequired,
}

ConfirmSolveModal.defaultProps = {
  character: null,
}

export default ConfirmSolveModal
