import React from 'react'
import PropTypes from 'prop-types'
import Card from 'semantic-ui-react/dist/commonjs/views/Card'
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid'
import Transition from 'semantic-ui-react/dist/commonjs/modules/Transition'
import shuffle from 'lodash/shuffle'

import MainMenu from './MainMenu'
import CharacterCard from './CharacterCard'
import characters from '../data/characters.json'
import settings from '../settings.json'

const animations = [
  'scale',
  'horizontal flip', 'vertical flip',
  'drop',
  'fly left', 'fly right', 'fly up', 'fly down',
  'slide down', 'slide up', 'slide right',
]

const TextScreen = ({ children }) => {
  const randomCharacters = shuffle(characters).slice(0, 4)
  return (
    <div>
      <MainMenu />
      <Grid padded relaxed>
        <Grid.Column width={3}>
          <Transition
            animation={animations[Math.floor(Math.random() * animations.length)]}
            duration={settings.splashScreenCardsAnimationDuration}
            transitionOnMount
          >
            <Card.Group itemsPerRow={1}>
              <CharacterCard character={randomCharacters[0]} showName={false} />
              <CharacterCard character={randomCharacters[1]} showName={false} />
            </Card.Group>
          </Transition>
        </Grid.Column>
        <Grid.Column width={10}>
          {children}
        </Grid.Column>
        <Grid.Column width={3}>
          <Transition
            animation={animations[Math.floor(Math.random() * animations.length)]}
            duration={settings.splashScreenCardsAnimationDuration}
            transitionOnMount
          >
            <Card.Group itemsPerRow={1}>
              <CharacterCard character={randomCharacters[2]} showName={false} />
              <CharacterCard character={randomCharacters[3]} showName={false} />
            </Card.Group>
          </Transition>
        </Grid.Column>
      </Grid>
    </div>
  )
}

TextScreen.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default TextScreen
