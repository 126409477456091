import React from 'react'
import Header from 'semantic-ui-react/dist/commonjs/elements/Header'
import List from 'semantic-ui-react/dist/commonjs/elements/List'

import StyledLink from './StyledLink'
import TextScreen from './TextScreen'

const AboutScreen = () => (
  <TextScreen>
    <Header as="h1">About</Header>
    <p>
      <em>Chess Who?</em>
      {' '}
was built by
      {' '}
      <StyledLink
        isExternal
        href="https://www.gingergm.com"
        text="Ginger GM"
      />
      {' '}
in 2018 as a fun way to use character images that had been created for another project.
    </p>

    <Header as="h2">Credits</Header>
    <ul>
      <li>
Developer:
        {' '}
        <StyledLink
          isExternal
          href="https://twitter.com/simon_ansell"
          text="@simon_ansell"
        />
      </li>
      <li>
Art:
        {' '}
        {' '}
our secret artist
      </li>
    </ul>

    <Header as="h2">Changelog</Header>
    <Header as="h3">
      v0.1.1
      <Header.Subheader>2018-01-25</Header.Subheader>
    </Header>
    <List bulleted>
      <List.Item>Optimise images</List.Item>
      <List.Item>Design changes</List.Item>
      <List.Item>Add logo</List.Item>
    </List>
  </TextScreen>
)

export default AboutScreen
