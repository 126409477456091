import React from 'react'
import PropTypes from 'prop-types'
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactRouterPropTypes from 'react-router-prop-types'
import styled from 'styled-components'

import { setConfirmModalStatusAction } from '../redux/actions'
import * as constants from '../redux/constants'

import packageSettings from '../../package.json'

const StyledMenu = styled(Menu)`
&&& {
  border-top: 4px solid rgb(255, 102, 0);
  border-radius: 0;
}
`

const MainMenu = ({
  history,
  match,
  gameStatus,
  setConfirmModalStatus,
}) => {
  const { path } = match
  return (
    <StyledMenu inverted size="huge">
      {gameStatus === constants.STATUS_READY
      && (
      <Link to="/" href="/">
        <Menu.Item active={path === '/'}>
          <Icon name="home" />
          Home
        </Menu.Item>
      </Link>
      )}
      {gameStatus !== constants.STATUS_READY
        && (
        <Menu.Item
          onClick={() => {
            if (path === '/') return setConfirmModalStatus(true)
            return history.push({
              pathname: '/',
            })
          }}
        >
          {path === '/'
            ? [<Icon name="close" key={0} />,
              <span key={1}>Exit game</span>]
            : [<Icon name="arrow left" key={0} />,
              <span key={1}>Back to game</span>]
           }
        </Menu.Item>
        )}
      {/* <Menu.Item disabled>
        <Icon name="setting" />
        Settings
      </Menu.Item> */}
      <Link to="/browse" href="/browse">
        <Menu.Item active={path === '/browse'}>
          <Icon name="list" />
          Browse
        </Menu.Item>
      </Link>
      <Link to="/help" href="/help">
        <Menu.Item active={path === '/help'}>
          <Icon name="help" />
          Help
        </Menu.Item>
      </Link>
      <Link to="/about" href="/about">
        <Menu.Item active={path === '/about'}>
          <Icon name="info" />
          About
        </Menu.Item>
      </Link>
      <Menu.Item position="right">
        v
        {packageSettings.version}
      </Menu.Item>
    </StyledMenu>
  )
}

/* eslint-disable react/no-typos */
// https://github.com/yannickcr/eslint-plugin-react/issues/1389
MainMenu.propTypes = {
  gameStatus: PropTypes.string.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  setConfirmModalStatus: PropTypes.func.isRequired,
}
/* eslint-enable react/no-typos */

const mapStateToProps = state => ({
  gameStatus: state.app.gameStatus,
})

const mapDispatchToProps = dispatch => ({
  setConfirmModalStatus: value => dispatch(setConfirmModalStatusAction(value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MainMenu))
