import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'

const A = styled.a`
text-decoration: underline;
&:hover {
  text-decoration: underline;
}
`

const StyledIcon = styled(Icon)`
&&& {
  margin-left: 0.2em;
  text-decoration: none;
}
`

const StyledLink = ({ href, text, isExternal }) => (
  <A
    href={href}
    rel={isExternal ? 'noopener noreferrer' : null}
    target={isExternal ? '_blank' : null}
  >
    {text}
    {isExternal && <StyledIcon name="external" />}
  </A>
)

StyledLink.propTypes = {
  href: PropTypes.string.isRequired,
  isExternal: PropTypes.bool,
  text: PropTypes.string.isRequired,
}
StyledLink.defaultProps = {
  isExternal: false,
}

export default StyledLink
