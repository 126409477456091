import React from 'react'
import PropTypes from 'prop-types'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal'

const ConfirmModal = ({
  isOpen,
  headerText,
  questionText,
  onYesClick,
  onNoClick,
}) => (
  <Modal
    open={isOpen}
    closeOnEscape={false}
    closeOnDimmerClick={false}
    size="tiny"
  >
    <Modal.Header>
      {headerText}
    </Modal.Header>
    <Modal.Content>
      <p>{questionText}</p>
    </Modal.Content>
    <Modal.Actions>
      <Button
        negative
        labelPosition="right"
        icon="close"
        content="No"
        onClick={onNoClick}
      />
      <Button
        positive
        labelPosition="right"
        icon="checkmark"
        content="Yes"
        onClick={onYesClick}
      />
    </Modal.Actions>
  </Modal>
)

ConfirmModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  headerText: PropTypes.string.isRequired,
  questionText: PropTypes.string.isRequired,
  onNoClick: PropTypes.func.isRequired,
  onYesClick: PropTypes.func.isRequired,
}

export default ConfirmModal
