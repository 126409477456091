import shuffle from 'lodash/shuffle'

import people from '../data/characters.json'
import * as constants from './constants'

export const appLoadedAction = ({ type: constants.APP_LOADED })
export const answerQuestionAction = (question, answer) => ({
  type: constants.ANSWER_QUESTION,
  payload: { question, answer },
})
export const askQuestionAction = questionKey => ({
  type: constants.ASK_QUESTION,
  payload: questionKey,
})
export const checkSolutionAction = character => ({
  type: constants.CHECK_SOLUTION,
  payload: character,
})
export const clearQuestionAskedAction = ({ type: constants.CLEAR_QUESTION_ASKED })
export const clearSolvingForCharacterAction = ({ type: constants.CLEAR_SOLVING_FOR_CHARACTER })
export const confirmSolveCharacterAction = character => ({
  type: constants.CONFIRM_SOLVE_CHARACTER,
  payload: character,
})
export const cpuTurnAction = ({ type: constants.CPU_TURN })
export const eliminateCharacterAction = character => ({
  type: constants.ELIMINATE_CHARACTER,
  payload: character,
})
export const eliminateCharactersAction = ({ type: constants.ELIMINATE_CHARACTERS })
export const incorrectGuessAction = ({ type: constants.INCORRECT_GUESS })
export const initGameAction = () => {
  // select 24 random characters from all available
  const characters = shuffle(people).slice(0, 24)
  return { type: constants.INIT_GAME, payload: characters }
}
export const prepareForEliminationAction = character => ({
  type: constants.PREPARE_FOR_ELIMINATION,
  payload: character,
})
export const resetGameAction = ({ type: constants.RESET_GAME })
export const selectCharacterAction = character => ({
  type: constants.SELECT_CHARACTER,
  payload: character,
})
export const setConfirmModalStatusAction = isOpen => ({
  type: constants.EXIT_GAME_MODAL_STATUS,
  payload: isOpen,
})
export const setGameStatusAction = status => ({ type: constants.SET_GAME_STATUS, payload: status })
export const setWinnerAction = ({ type: constants.SET_WINNER })
export const solveForCharacterAction = character => ({
  type: constants.SOLVE_FOR_CHARACTER,
  payload: character,
})
export const toggleActivePlayerAction = ({ type: constants.TOGGLE_ACTIVE_PLAYER })
