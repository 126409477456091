import React from 'react'
import PropTypes from 'prop-types'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid'
import styled from 'styled-components'
import omit from 'lodash/omit'

import { characterPropType } from './propTypes'
import CharacterCard from './CharacterCard'

const StyledColumn = styled(props => <Grid.Column {...omit(props, ['padding'])} />)`
&&& {
  padding: ${props => props.padding}px !important;
}
`

const CharacterGrid = ({
  characters,
  columns,
  cursorAsPointer,
  isZoomEnabled,
  onCharacterClick,
  padding,
  raisedCharacters,
  showName,
  showSmallNames,
}) => (
  <Segment raised={raisedCharacters}>
    <Grid columns={columns}>
      {characters.map(c => (
        <StyledColumn key={c.character.id} padding={padding}>
          <CharacterCard
            character={c.character}
            cursorAsPointer={cursorAsPointer}
            isEliminated={c.isEliminated}
            isRaised={raisedCharacters}
            isZoomEnabled={!c.isEliminated && isZoomEnabled}
            onClick={c.isEliminated ? null : onCharacterClick}
            showName={showName}
            showSmallNames={showSmallNames}
            willBeEliminated={c.willBeEliminated}
          />
        </StyledColumn>
      ))}
    </Grid>
  </Segment>
)

CharacterGrid.propTypes = {
  characters: PropTypes.arrayOf(PropTypes.shape({
    characterPropType,
  })).isRequired,
  columns: PropTypes.number,
  cursorAsPointer: PropTypes.bool,
  isZoomEnabled: PropTypes.bool,
  onCharacterClick: PropTypes.func,
  padding: PropTypes.number,
  raisedCharacters: PropTypes.bool,
  showName: PropTypes.bool,
  showSmallNames: PropTypes.bool,
}

CharacterGrid.defaultProps = {
  columns: 6,
  cursorAsPointer: false,
  isZoomEnabled: false,
  onCharacterClick: null,
  padding: 4,
  raisedCharacters: false,
  showName: true,
  showSmallNames: false,
}

export default CharacterGrid
