import React from 'react'
import Header from 'semantic-ui-react/dist/commonjs/elements/Header'
import styled from 'styled-components'
import Image from 'semantic-ui-react/dist/commonjs/elements/Image'

import StyledLink from '../StyledLink'

const H1 = styled(Header)`
&&& {
  align-items: center;
  display: flex;
  margin-top: 0;
  font-weight: 400;
}
`

const ByText = styled.span`
margin-right: 0.3em;
`

const StyledImage = styled(Image)`
&&& {
  height: 110px;
  width: 230px;
}
`

const Logo = () => (
  <H1 as="h1">
    <StyledImage src="/chess-who.png" alt="Chess Who? by Ginger GM" />
    <ByText>by</ByText>
    <StyledLink
      isExternal
      href="https://www.gingergm.com"
      text="Ginger GM"
    />
  </H1>
)

export default Logo
