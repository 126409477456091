import React from 'react'
import Header from 'semantic-ui-react/dist/commonjs/elements/Header'

import TextScreen from './TextScreen'

const HelpScreen = () => (
  <TextScreen>
    <Header as="h1">Help</Header>
    <p>
      <em>Chess Who?</em>
      {' '}
is based on the popular childrens board game of a similar name.
    </p>
    <p>
      Each player starts the game with a board that includes images of several
      characters and their first names with all the images face up.
    </p>
    <p>
      Each player selects a character of their choice from the same images. The object of the game
      is to be the first to determine which character one&apos;s opponent has selected.
    </p>
    <p>
Players alternate asking various yes or no questions to eliminate characters,
      such as
      {' '}
      <em>&ldquo;Does this person wear glasses?&rdquo;</em>
. Well-crafted questions allow
      players to eliminate one or more possible cards.
    </p>
    <p>
      A player may guess the opponent&apos;s character instead of asking a question. Guessing
      correctly wins the game.
    </p>
  </TextScreen>
)

export default HelpScreen
