import React from 'react'
import PropTypes from 'prop-types'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid'
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal'
import Header from 'semantic-ui-react/dist/commonjs/elements/Header'
import Transition from 'semantic-ui-react/dist/commonjs/modules/Transition'

import CharacterCard from './CharacterCard'
import settings from '../settings.json'
import { characterPropType } from './propTypes'

const GameOverModal = ({
  isOpen,
  isPlayerWinner,
  onExitClick,
  onNewGameClick,
  opponentCharacter,
  playerCharacter,
}) => (
  <Transition
    animation="fly down"
    duration={settings.modalFlyInDuration}
    visible={isOpen}
  >
    <Modal
      open={isOpen}
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        Game over:
        {' '}
        {isPlayerWinner ? 'You win!' : 'You lose!'}
      </Modal.Header>
      <Modal.Content>
        <Grid padded>
          <Grid.Column width={8}>
            <Header as="h3">
              Your character
            </Header>
            <CharacterCard
              character={playerCharacter}
              isCentered={false}
              showMeta
              showExtraContent
              showName={false}
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <Header as="h3">
              Opponent (CPU) character
            </Header>
            <CharacterCard
              character={opponentCharacter}
              isCentered={false}
              showMeta
              showExtraContent
              showName={false}
            />
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          negative
          labelPosition="right"
          icon="close"
          content="Exit"
          onClick={onExitClick}
        />
        <Button
          positive
          labelPosition="right"
          icon="checkmark"
          content="New game"
          onClick={onNewGameClick}
        />
      </Modal.Actions>
    </Modal>
  </Transition>
)

GameOverModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPlayerWinner: PropTypes.bool.isRequired,
  onExitClick: PropTypes.func.isRequired,
  onNewGameClick: PropTypes.func.isRequired,
  opponentCharacter: characterPropType,
  playerCharacter: characterPropType,
}

GameOverModal.defaultProps = {
  opponentCharacter: null,
  playerCharacter: null,
}

export default GameOverModal
